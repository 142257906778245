import {
  styles,
  viewCodBarra,
  mostrarCodigo,
  mostrarLogoSucursal,
  mostrarNombreSucursal,
  mostrarPrecioArticulo,
  mostrarNombreProveedor,
  mostrarUbicacionArticulo,
  mostrarDescripcionArticulo,
  mostrarFechaImpresion,
  mostrarDivisorDeCodigos,
} from "../Reutilizables";
import { Page, View, Document, Font } from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";
import React from "react";

export default function EtiquetasTicket({
  alto,
  ancho,
  sucursal,
  articulo,
  configGeneral,
  configuracionEtiqueta,
}) {
  // Configuracion de detalles
  const handleMostrarPrecioArticulo = () => {
    let precio;

    switch (configuracionEtiqueta.precioAMostrar) {
      case "venta":
        precio = articulo.precios.venta;
        break;
      case "costo":
        precio = articulo.precios.costo;
        break;
      case "lista":
        precio = articulo.precios.lista;
        break;
    }

    if (configuracionEtiqueta.cifrarPrecio) {
      precio = handleCifrarPrecio(precio);
    } else {
      precio = `$${parseInt(precio).toLocaleString("ES-ar")}`;
    }

    return precio;
  };

  const handleCifrarPrecio = (precio) => {
    const mapeo = {
      1: "E",
      2: "C",
      3: "L",
      4: "U",
      5: "Y",
      6: "I",
      7: "N",
      8: "T",
      9: "A",
      0: "R",
      ".": "/",
    };

    const precioCifrado = precio
      .toString()
      .split("")
      .map((caracter) => mapeo[caracter] || caracter)
      .join("");

    return precioCifrado;
  };

  const handleGenerarCodigoBarras = () => {
    switch (configuracionEtiqueta.codigoBarrasDesde) {
      case "proveedor":
        return articulo.codProveedor ? articulo.codProveedor : "000";
      case "original":
        return articulo.codOriginal ? articulo.codOriginal : "000";
      case "auxiliar":
        return articulo.codAuxiliar ? articulo.codAuxiliar : "000";
      default:
        return "---";
    }
  };

  const handleMostrarCodigoDeBarras = () => {
    // Si el codigo seleccionado existe en el articulo muestra el codig ode barras
    switch (configuracionEtiqueta.codigoBarrasDesde) {
      case "proveedor":
        return articulo.codProveedor ? true : false;
      case "original":
        return articulo.codOriginal ? true : false;
      case "auxiliar":
        return articulo.codAuxiliar ? true : false;
      default:
        return false;
    }
  };
  // Configuracion de detalles

  // Configuracion Barcode
  JsBarcode("#barcode", handleGenerarCodigoBarras(), {
    lineColor: "#000",
    width: 4,
    height: 80,
    displayValue: false,
  });

  const canvas = document.getElementById("barcode");
  const pngUrl = canvas.toDataURL("image/png");
  // Configuracion Barcode

  console.log(configuracionEtiqueta);

  return (
    //1px = 0.36 mm
    <Document title={`EtiquetaTicket`}>
      <Page
        size={{
          width: ancho / 0.36,
          minHeight: alto / 0.36,
          maxHeight: alto / 0.36,
        }}
        style={styles.page}
      >
        <View style={styles.contenedor}>
          <View style={styles.tabla}>
            <View style={styles.fila}>
              <View
                style={{
                  width: ancho / 0.36,
                  height: alto / 0.36,
                  maxHeight: alto / 0.36,
                  padding: 5,
                }}
              >
                {configuracionEtiqueta.nombreNegocio
                  ? mostrarNombreSucursal(
                      sucursal.razonSocial,
                      configuracionEtiqueta.tamanioTipografia,
                    )
                  : null}
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    marginTop: -10,
                    marginBottom: -10,
                    gap: 10,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  {configuracionEtiqueta.logotipo
                    ? mostrarLogoSucursal(configGeneral.url_imagen)
                    : null}

                  {handleMostrarCodigoDeBarras()
                    ? viewCodBarra(
                        pngUrl,
                        Boolean(configuracionEtiqueta.logotipo),
                      )
                    : null}
                </View>
                <View
                  style={{
                    marginBottom: 5,
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {configuracionEtiqueta.codigoProveedor
                    ? mostrarCodigo(
                        articulo.codProveedor,
                        configuracionEtiqueta.tamanioTipografia,
                      )
                    : null}

                  {configuracionEtiqueta.codigoProveedor &&
                  articulo.codProveedor &&
                  configuracionEtiqueta.codigoOriginal &&
                  articulo.codOriginal
                    ? mostrarDivisorDeCodigos()
                    : null}

                  {configuracionEtiqueta.codigoOriginal
                    ? mostrarCodigo(
                        articulo.codOriginal,
                        configuracionEtiqueta.tamanioTipografia,
                      )
                    : null}

                  {configuracionEtiqueta.codigoOriginal &&
                  articulo.codOriginal &&
                  configuracionEtiqueta.codigoAuxiliar &&
                  articulo.codAuxiliar
                    ? mostrarDivisorDeCodigos()
                    : null}

                  {configuracionEtiqueta.codigoAuxiliar
                    ? mostrarCodigo(
                        articulo.codAuxiliar,
                        configuracionEtiqueta.tamanioTipografia,
                      )
                    : null}
                </View>
                {configuracionEtiqueta.descripcion
                  ? mostrarDescripcionArticulo(
                      articulo.descripcionProveedor,
                      configuracionEtiqueta.tamanioTipografia,
                    )
                  : null}
                <View
                  style={{
                    width: "100%",
                    display: "flex",
                    marginBottom: 5,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {configuracionEtiqueta.nombreProveedor ||
                  configuracionEtiqueta.numeroProveedor
                    ? mostrarNombreProveedor(
                        configuracionEtiqueta.tamanioTipografia,
                        articulo.proveedor,
                        configuracionEtiqueta.nombreProveedor,
                        articulo.numero_proveedor,
                        configuracionEtiqueta.numeroProveedor,
                      )
                    : null}
                  {configuracionEtiqueta.precio
                    ? mostrarPrecioArticulo(
                        handleMostrarPrecioArticulo(),
                        configuracionEtiqueta.tamanioTipografia,
                      )
                    : null}
                </View>
                {configuracionEtiqueta.ubicacion
                  ? mostrarUbicacionArticulo(
                      articulo.repuesto_sucursal,
                      configuracionEtiqueta.tamanioTipografia,
                    )
                  : null}
                {configuracionEtiqueta.fechaImpresion
                  ? mostrarFechaImpresion(
                      configuracionEtiqueta.tamanioTipografia,
                    )
                  : null}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
